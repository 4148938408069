export const MshuttleLogo = () => {
  return (
    <svg
      width="84"
      height="71"
      viewBox="0 0 84 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.1057 5.27785H21.478V25.9156H16.9902V0.79007H42.1057V5.27785Z"
        fill="#4FD3FC"
      />
      <path
        d="M42.1097 25.6399H47.0201C48.2578 28.88 51.8903 30.5 55.1303 29.2623C58.3704 28.0247 59.9904 24.3922 58.7528 21.1521C57.5151 17.9121 53.8826 16.292 50.6426 17.5297C48.9722 18.1636 47.6541 19.4818 47.0201 21.1521H42.1097V25.6399ZM51.0853 23.396C51.0853 22.4099 51.8903 21.6049 52.8764 21.6049C53.8625 21.6049 54.6675 22.4099 54.6675 23.396C54.6675 24.3821 53.8625 25.1871 52.8764 25.1871C51.8903 25.1871 51.0853 24.3922 51.0853 23.396Z"
        fill="#4852E3"
      />
      <path
        d="M25.0642 23.396C25.0642 26.8574 27.8716 29.6749 31.333 29.6749C33.9391 29.6749 36.2736 28.075 37.1993 25.6399H42.1097V21.1521H37.1993C35.9617 17.9121 32.3392 16.292 29.0992 17.5297C26.6641 18.4554 25.0642 20.7899 25.0642 23.396ZM29.552 23.396C29.552 22.4099 30.357 21.6049 31.3431 21.6049C32.3292 21.6049 33.1342 22.4099 33.1342 23.396C33.1342 24.3821 32.3292 25.1871 31.3431 25.1871C30.357 25.1871 29.552 24.3922 29.552 23.396Z"
        fill="#4FD3FC"
      />
      <path
        d="M67.0099 0.79007V25.9156H62.5221V5.27785H41.8944V0.79007H67.0099Z"
        fill="#4852E3"
      />
      <path
        d="M33.588 45.6309C30.5693 45.6309 28.1241 48.0761 28.1241 51.0948C28.1241 54.1134 30.5693 56.5586 33.588 56.5586C36.6067 56.5586 39.0518 54.1134 39.0518 51.0948C39.0518 48.0761 36.6067 45.6309 33.588 45.6309ZM33.588 52.6544C32.7226 52.6544 32.0283 51.9601 32.0283 51.0948C32.0283 50.2294 32.7226 49.5351 33.588 49.5351C34.4533 49.5351 35.1476 50.2294 35.1476 51.0948C35.1476 51.9601 34.4533 52.6544 33.588 52.6544Z"
        fill="#4FD3FC"
      />
      <path
        d="M10.9377 56.4982V45.7114H0V63.4412H10.9377V59.5873H3.90417V56.5083L10.9377 56.4982ZM7.03354 52.6444H3.91423V49.5653H7.03354V52.6444Z"
        fill="#4852E3"
      />
      <path
        d="M81.2328 56.5686V52.6645H74.1993V49.5452H81.2328V45.6309H70.3052V56.5686H81.2328Z"
        fill="#4852E3"
      />
      <path
        d="M81.2329 70.4647V66.5605H76.9664L84 59.527H70.3052V63.4311H74.5716L67.5381 70.4647H81.2329Z"
        fill="#4852E3"
      />
      <path
        d="M81.2429 38.6779H70.3052V42.5821H81.2429V38.6779Z"
        fill="#4852E3"
      />
      <path
        d="M39.0588 59.6467H28.1211V63.5509H39.0588V59.6467Z"
        fill="#4852E3"
      />
      <path
        d="M46.0823 45.5897H42.1781V56.5274H46.0823V45.5897Z"
        fill="#4852E3"
      />
      <path
        d="M14.057 70.4647H17.9612V63.4311H24.9847V59.527H14.057V70.4647Z"
        fill="#4852E3"
      />
      <path
        d="M14.057 56.5686H24.9947V52.6645H17.9612V49.5452H24.9947V45.6309H14.057V56.5686Z"
        fill="#4852E3"
      />
      <path
        d="M58.1903 45.7114V49.5653H63.2717V52.6444H59.0053L56.6306 50.2998V45.7114H52.7264V50.3099L48.3997 54.5763L51.1567 57.3032L54.6685 53.8317L58.1802 57.3032L58.9852 56.5083H63.2516V63.4512H67.1558V45.7114H58.1903Z"
        fill="#4852E3"
      />
    </svg>
  );
};
