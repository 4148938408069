import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  colors,
  pointerEvt,
  Subtitle4,
  Body3,
  contents,
  Caption1,
  Button,
  Up,
} from "ms-ui";
import styled from "styled-components";
import { Facebook } from "./Facebook";
import { Instagram } from "./Instagram";
import { Kakao } from "./Kakao";

interface IFooter {
  onTopButtonClick: () => void;
  onFacebookClick: () => void;
  onInstaClick: () => void;
  onKakaoClick: () => void;
  onCallClick: () => void;
  onMailClick: () => void;
  onPrivacyTermsClick: () => void;
  onLocationTermsClick: () => void;
  onUseTermsClick: () => void;
  onBusinessTermsClick: () => void;
}

export const Footer = observer(
  ({
    onTopButtonClick,
    onFacebookClick,
    onInstaClick,
    onKakaoClick,
    onCallClick,
    onMailClick,
    onPrivacyTermsClick,
    onLocationTermsClick,
    onUseTermsClick,
    onBusinessTermsClick,
  }: IFooter) => {
    return (
      <>
        <StDiv1>
          <StDiv2>
            <StDiv2_2 onClick={onFacebookClick} data-id={"main_fb"}>
              <Facebook />
            </StDiv2_2>

            <StDiv2_2 onClick={onInstaClick} data-id={"main_ig"}>
              <Instagram />
            </StDiv2_2>

            <StDiv2_2 onClick={onKakaoClick} data-id={"main_kakao"}>
              <Kakao />
            </StDiv2_2>
          </StDiv2>
          <StDiv3>
            <StDiv4>고객센터</StDiv4>
            <StDiv5>
              <StDiv6>
                <StDiv7 onClick={onCallClick}>
                  <StDiv8>전화</StDiv8>
                  <StDiv9>02 775 1008</StDiv9>
                </StDiv7>
                <StDiv10 onClick={onMailClick}>
                  <StDiv11>이메일</StDiv11>
                  <StDiv12>contact@mshuttle.co.kr</StDiv12>
                </StDiv10>
              </StDiv6>
              <StDiv13>
                <StDiv14>운영시간</StDiv14>
                <StDiv15>
                  <StDiv16>
                    <StDiv17>평일</StDiv17>
                    <StDiv18>|</StDiv18>
                    <StDiv19>06:30 ~ 18:00</StDiv19>
                  </StDiv16>
                  <StDiv20>
                    <StDiv21>점심</StDiv21>
                    <StDiv22>|</StDiv22>
                    <StDiv23>13:00 ~ 14:00</StDiv23>
                  </StDiv20>
                  <StDiv24>주말 및 공휴일 휴무</StDiv24>
                </StDiv15>
              </StDiv13>
              <StDiv25>
                <StDiv26>
                  <StDiv27>주식회사 모두의셔틀</StDiv27>
                </StDiv26>
                <StDiv28>
                  <StDiv29>
                    <StDiv30>대표</StDiv30>
                    <StDiv31>장지환</StDiv31>
                  </StDiv29>
                  <StDiv32>
                    <StDiv33>정보보호 담당자</StDiv33>
                    <StDiv34>김필수</StDiv34>
                  </StDiv32>
                  <StDiv35>
                    <StDiv36>주소</StDiv36>
                    <StDiv37>서울특별시 서초구 반포대로 22길 39, 202호</StDiv37>
                  </StDiv35>
                  <StDiv38>
                    <StDiv39>사업자등록번호</StDiv39>
                    <StDiv40>135-88-00584</StDiv40>
                  </StDiv38>
                  <StDiv41>
                    <StDiv42>통신판매업신고</StDiv42>
                    <StDiv43>2019-서울강남-00517</StDiv43>
                  </StDiv41>
                </StDiv28>
              </StDiv25>
              <StDiv44>
                <StDiv45 onClick={onPrivacyTermsClick}>
                  개인정보취급방침
                </StDiv45>
                <StDiv46 onClick={onLocationTermsClick}>
                  위치기반서비스 이용약관
                </StDiv46>
                <StDiv47 onClick={onUseTermsClick}>이용약관</StDiv47>
                <StDiv48 onClick={onBusinessTermsClick}>
                  사업자정보 확인
                </StDiv48>
              </StDiv44>
            </StDiv5>
            <StDiv49>Copyright © 2021 모두의셔틀 All rights reserved.</StDiv49>
          </StDiv3>
          <Button
            color={"black"}
            size={"rg"}
            radius={"5"}
            outline={true}
            width={"100%"}
            onClick={onTopButtonClick}
          >
            <StButtonText>
              맨 위로&nbsp;
              <Up color={contents.subtitle.value} />
            </StButtonText>
          </Button>
        </StDiv1>
      </>
    );
  }
);

const StButtonText = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
`;

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 16px 64px 16px;
  flex-direction: column;
  gap: ${spacing[32].value};
  background: ${colors.mainblue[700].value};
`;

const StDiv2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing[12].value};
  width: 100%;
`;

const StDiv2_2 = styled.div<{ "data-id": string }>`
  ${(p) => pointerEvt(p["data-id"])}
  cursor: pointer;
`;

const StDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[24].value};
  width: 100%;
`;

const StDiv4 = styled.div`
  ${Subtitle4};
  align-self: stretch;
  color: ${colors.gray["000"].value};
`;

const StDiv5 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[20].value};
  width: 100%;
`;

const StDiv6 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv7 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  cursor: pointer;
  width: 100%;
`;

const StDiv8 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv9 = styled.div`
  ${Subtitle4};
  flex: 1 0 0;
  color: ${colors.gray["000"].value};
`;

const StDiv10 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  cursor: pointer;
  width: 100%;
`;

const StDiv11 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv12 = styled.div`
  ${Subtitle4};
  flex: 1 0 0;
  color: ${colors.gray["000"].value};
`;

const StDiv13 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4].value};
  width: 100%;
`;

const StDiv14 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv15 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const StDiv16 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv17 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv18 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv19 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv20 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv21 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv22 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv23 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv24 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv25 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4].value};
  width: 100%;
`;

const StDiv26 = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const StDiv27 = styled.div`
  ${Subtitle4};
  flex: 1 0 0;
  color: ${colors.gray["000"].value};
`;

const StDiv28 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv29 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv30 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv31 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv32 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv33 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv34 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv35 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv36 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv37 = styled.div`
  ${Subtitle4};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv38 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv39 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv40 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv41 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv42 = styled.div`
  ${Body3};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv43 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  width: fit-content;
`;

const StDiv44 = styled.div`
  display: flex;
  width: 328px;
  flex-direction: column;
  justify-content: center;
  gap: ${spacing[4].value};
`;

const StDiv45 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  cursor: pointer;
  width: fit-content;
`;

const StDiv46 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  cursor: pointer;
  width: fit-content;
`;

const StDiv47 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  cursor: pointer;
  width: fit-content;
`;

const StDiv48 = styled.div`
  ${Subtitle4};
  color: ${colors.gray["000"].value};
  cursor: pointer;
  width: fit-content;
`;

const StDiv49 = styled.div`
  ${Caption1};
  align-self: stretch;
  color: ${colors.gray["000"].value};
`;
