export const Medal = () => {
  return (
    <svg
      width="32"
      height="59"
      viewBox="0 0 32 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1477_4657)">
        <path
          d="M31.5 1.72V20.09C31.5 20.63 31.25 21.14 30.82 21.46L24.5 26.25L17.04 31.91C16.43 32.38 15.57 32.38 14.96 31.91L7.5 26.25L1.18 21.46C0.75 21.14 0.5 20.63 0.5 20.09V1.72C0.5 0.77 1.27 0 2.22 0H29.78C30.73 0 31.5 0.77 31.5 1.72Z"
          fill="#4F70FC"
        />
        <path
          d="M29.78 0H16V32.26C16.38 32.26 16.74 32.14 17.04 31.91L24.5 26.25L30.82 21.46C31.25 21.13 31.5 20.63 31.5 20.09V1.72C31.5 0.77 30.73 0 29.78 0Z"
          fill="#3A5BE6"
        />
        <path
          d="M24.5 0V26.25L17.04 31.91C16.43 32.38 15.57 32.38 14.96 31.91L7.5 26.25V0H24.5Z"
          fill="#4852E3"
        />
        <path
          d="M16 0V32.26C16.38 32.26 16.74 32.14 17.04 31.91L24.5 26.25V0H16Z"
          fill="#3A45DE"
        />
        <path
          d="M16 28.8201C7.73002 28.8201 1.02002 35.5301 1.02002 43.8001C1.02002 52.0701 7.73002 58.7801 16 58.7801C24.27 58.7801 30.98 52.0701 30.98 43.8001C30.97 35.5301 24.27 28.8301 16 28.8201Z"
          fill="#FAD557"
        />
        <path
          d="M16 28.8201V58.7901C24.28 58.7901 30.98 52.0801 30.98 43.8101C30.98 35.5301 24.28 28.8201 16 28.8201Z"
          fill="#FCB12B"
        />
        <path
          d="M17.6201 36.7354H11.77V39.5752H14.3599V51.4053H17.6201V36.7354Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1477_4657">
          <rect
            width="31"
            height="58.7901"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
