import React from "react";
import { Footer } from "./FooterView";
import { winCall, winMail, winOpen } from "src/func/window";
import { channelIO } from "src/func/tracking/channel";

const kakao = "https://goo.gl/J0ws8e"; // 카카오
const facebook = "https://www.facebook.com/mosher.zone/"; // 페이스북
const insta = "https://www.instagram.com/modooshuttle/"; // 인스타그램

export const FooterComp = () => {
  return (
    <Footer
      onTopButtonClick={() => {
        window.scroll({ top: 0 });
      }}
      onFacebookClick={() => {
        winOpen(facebook, "_blank");
      }}
      onInstaClick={() => {
        winOpen(insta, "_blank");
      }}
      onKakaoClick={() => {
        winOpen(kakao, "_blank");
      }}
      onCallClick={() => {
        winCall("02 775 1008");
        channelIO.event("callto", {});
      }}
      onMailClick={() => {
        winMail("contact@mshuttle.co.kr");
        channelIO.event("mailto", {});
      }}
      onPrivacyTermsClick={() => {
        winOpen(
          `https://guide.modooshuttle.com/7998e83d-5799-494f-8def-8e3620d5231b`,
          "_blank"
        );
      }}
      onLocationTermsClick={() => {
        winOpen(
          `https://guide.modooshuttle.com/87086323-ffff-4884-8a38-078d8ccb8bb2`,
          "_blank"
        );
      }}
      onUseTermsClick={() => {
        winOpen(
          `https://guide.modooshuttle.com/ce649b92-7697-4b41-b044-2c1af03907e6`,
          "_blank"
        );
      }}
      onBusinessTermsClick={() => {
        winOpen(
          "http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1358800584",
          "width=750, height=700;"
        );
      }}
    />
  );
};
