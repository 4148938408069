/**
 * 새창 열기
 * @param url
 */
export const winOpen = (url: string, target?: string, features?: string) => {
  let name = target || "_blank";
  window.open(url, name, features);
};

/**
 * 전화걸기
 */
export const winCall = (phone: string) => {
  window.location.href = `tel:${phone}`;
};

/**
 * 전화걸기
 */
export const winMail = (mail: string) => {
  window.location.href = `mailto:${mail}`;
};

export const loadScript = async (filePath: string) => {
  return new Promise((rs: any, re) => {
    const body = document.getElementsByTagName("body")[0];
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = filePath;

    body.appendChild(script);
    script.onload = () => {
      rs();
    };
  });
};
