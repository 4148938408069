import { useEffect, useRef, useState } from "react";
import { register } from "swiper/element/bundle";
import { SwiperSlideProps } from "swiper/react";
import { SwiperOptions } from "swiper/types";

interface ISwiperProps extends SwiperOptions {
  children: any;
}

export const Swiper = ({ children, ...rest }: ISwiperProps) => {
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    // Register Swiper web component
    register();

    // Swiper 초기화
    if (swiperRef.current) {
      const params: SwiperOptions = {
        ...rest,
        injectStyles: [
          ...(rest.injectStyles || []),
          `
          :host {
            --font-sizes-11: 11rem;
            --font-sizes-13: 13rem;
            --font-sizes-15: 15rem;
            --font-sizes-16: 16rem;
            --font-sizes-18: 18rem;
            --font-sizes-20: 20rem;
            --font-sizes-24: 24rem;
            --font-sizes-25: 25rem;
            --font-sizes-28: 28rem;
            --font-sizes-32: 32rem;
            --font-weights-bold: 700;
            --font-weights-regular: 400;
            --font-families-noto-sans: Noto Sans KR;
            --font-families-gmarket-sans: Gmarket Sans;
            --opacity-16: 0.16;
            --opacity-08: 0.08;
            --box-shadow-400: 0px 0px 10px 0px #00000008;
            --box-shadow-500: 0px 3px 16px 0px #00000016;
            --box-shadow-600: 0px 3px 16px 0px #00000028;
            --line-heights-18: 18px;
            --line-heights-20: 20px;
            --line-heights-24: 24px;
            --line-heights-25: 25px;
            --line-heights-28: 28px;
            --line-heights-32: 32px;
            --border-width-1: 1px;
            --letter-spacing-normal: -0.02em;
            --opaque-25: rgba(0, 0, 0, 0.25);
            --opaque-50: rgba(0, 0, 0, 0.5);
            --opaque-70: rgba(0, 0, 0, 0.7);
            --border-radius-0: 0px;
            --border-radius-5: 5px;
            --border-radius-10: 10px;
            --border-radius-15: 15px;
            --spacing-4: 4px;
            --spacing-8: 8px;
            --spacing-12: 12px;
            --spacing-16: 16px;
            --spacing-20: 20px;
            --spacing-24: 24px;
            --spacing-28: 28px;
            --spacing-32: 32px;
            --spacing-36: 36px;
            --spacing-40: 40px;
            --spacing-44: 44px;
            --spacing-48: 48px;
            --spacing-52: 52px;
            --spacing-56: 56px;
            --spacing-60: 60px;
            --spacing-64: 64px;
            --colors-mainblue-100: #729aff;
            --colors-mainblue-200: #6490ff;
            --colors-mainblue-300: #5585ff;
            --colors-mainblue-400: #4479ff;
            --colors-mainblue-500: #4852e3;
            --colors-mainblue-600: #232caa;
            --colors-mainblue-700: #141960;
            --colors-mainblue-040: #eef3fe;
            --colors-mainblue-050: #d2e4f9;
            --colors-subblue-100: #87e1fc;
            --colors-subblue-200: #7bdefc;
            --colors-subblue-300: #6edbfc;
            --colors-subblue-400: #5fd7fc;
            --colors-subblue-500: #4fd3fc;
            --colors-subblue-600: #02b1ff;
            --colors-subblue-700: #02a1e8;
            --colors-subblue-040: #eafaff;
            --colors-subblue-050: #e1f8ff;
            --colors-gray-100: #e9ebee;
            --colors-gray-200: #babcbf;
            --colors-gray-300: #8c8c8c;
            --colors-gray-400: #4c4c4c;
            --colors-gray-500: #1a202c;
            --colors-gray-600: #181d28;
            --colors-gray-700: #161a24;
            --colors-gray-000: #ffffff;
            --colors-gray-090: #f4f6f8;
            --colors-red-100: #fc9696;
            --colors-red-200: #fc8b8b;
            --colors-red-300: #fc7f7f;
            --colors-red-400: #fc7272;
            --colors-red-500: #fc6464;
            --colors-red-600: #eb4545;
            --colors-red-700: #d63f3f;
            --colors-red-040: #fff9f9;
            --colors-red-050: #fff2f2;
            --colors-green-100: #54dea9;
            --colors-green-200: #43dba0;
            --colors-green-300: #30d796;
            --colors-green-400: #1bd38c;
            --colors-green-500: #00bc7b;
            --colors-green-600: #009562;
            --colors-green-700: #008759;
            --colors-green-040: #eafbf5;
            --colors-green-050: #d5f7ea;
            --colors-yellow-100: #ffef2c;
            --colors-yellow-200: #ffed17;
            --colors-yellow-300: #ffeb00;
            --colors-yellow-400: #f7e117;
            --colors-yellow-500: #ffc700;
            --colors-yellow-600: #e8b500;
            --colors-yellow-700: #d3a500;
            --colors-yellow-040: #fffde5;
            --colors-yellow-050: #fffbcb;
            --contents-primary: #1a202c;
            --contents-secondary: #ffffff;
            --contents-tertiary: #e9ebee;
            --contents-description: #babcbf;
            --contents-background: #f4f6f8;
            --contents-subtitle: #4c4c4c;
            --contents-accent: #8c8c8c;
            --foundation-normal-primary: #4852e3;
            --foundation-normal-secondary: #4fd3fc;
            --foundation-normal-warning: #ffc700;
            --foundation-normal-negative: #fc6464;
            --foundation-normal-positive: #00bc7b;
            --foundation-normal-black: #4c4c4c;
            --foundation-light-primary: #eef3fe;
            --foundation-light-secondary: #eafaff;
            --foundation-light-warning: #fffde5;
            --foundation-light-negative: #fff9f9;
            --foundation-light-positive: #eafbf5;
            --foundation-light-black: #f4f6f8;
            --foundation-dark-primary: #232caa;
            --foundation-dark-secondary: #02b1ff;
            --foundation-dark-warning: #e8b500;
            --foundation-dark-negative: #eb4545;
            --foundation-dark-positive: #009562;
            --foundation-dark-black: #1a202c;
            --mshuttle-runn: #4fd3fc;
            --mshuttle-schd: #4852e3;
            --mshuttle-start: #00bc7b;
            --mshuttle-end: #fc6464;

          }

        `,
        ],
      };

      Object.assign(swiperRef.current, params);
      swiperRef.current.initialize();
    }
  }, [rest]);

  return (
    <swiper-container init="false" ref={swiperRef}>
      {children}
    </swiper-container>
  );
};

interface ISwiperSlideProps extends SwiperSlideProps {
  children: any;
}
export function SwiperSlide(props: ISwiperSlideProps) {
  const { children, ...rest } = props;

  return <swiper-slide {...rest}>{children}</swiper-slide>;
}
