import React from "react";
import { observer } from "mobx-react";
import {
  Paragraph,
  spacing,
  colors,
  contents,
  Header2,
  Body2,
  borderRadius,
  Header3,
  foundation,
  Body1,
  Body3,
} from "ms-ui";
import styled from "styled-components";
import { Medal } from "./Medal";
import { MshuttleLogo } from "./MshuttleLogo";

export const MemberShip = observer(() => {
  return (
    <>
      <StDiv1 noMargin={true}>
        <StDiv2>
          <Medal />
        </StDiv2>
        <StDiv3>
          <StDiv4>
            <MshuttleLogo />
          </StDiv4>
        </StDiv3>
        <StDiv5>
          <StDiv6>
            1위 출퇴근 공유셔틀
            <br />
            멤버십 서비스!
          </StDiv6>
          <StDiv7>
            <StDiv8>
              피곤에 지친 직장인분들을 위해
              <br />
              동네별 편안한 출근 여정을 만들어 드립니다.
            </StDiv8>
            <StDiv9>*퇴근 확대 예정(현재 기업은 운영 중)</StDiv9>
          </StDiv7>
        </StDiv5>
        <StDiv10>
          <StDiv11>
            <StDiv12>
              <StDiv13>
                <StDiv14>80,000</StDiv14>
                <StDiv15>+</StDiv15>
              </StDiv13>
              <StDiv16>
                모셔드린
                <br />
                멤버
              </StDiv16>
            </StDiv12>
            <StDiv17>
              <StDiv18>
                <StDiv19>200</StDiv19>
                <StDiv20>+</StDiv20>
              </StDiv18>
              <StDiv21>
                운행중
                <br />
                출근길
              </StDiv21>
            </StDiv17>
            <StDiv22>
              <StDiv23>
                <StDiv24>600</StDiv24>
                <StDiv25>+</StDiv25>
              </StDiv23>
              <StDiv26>
                운행 예정
                <br />
                출근길
              </StDiv26>
            </StDiv22>
          </StDiv11>
          <StDiv27>2021년 4월 기준</StDiv27>
        </StDiv10>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled(Paragraph)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing[24].value};
  background: ${colors.mainblue["040"].value};
`;

const StDiv2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  padding: 0px 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const StDiv4 = styled.div`
  display: flex;
  padding: ${spacing[20].value} ${spacing[12].value};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${contents.secondary.value};
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.09);
  width: fit-content;
`;

const StDiv5 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const StDiv6 = styled.div`
  ${Header2};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv7 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv8 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  text-align: center;
  width: fit-content;
`;

const StDiv9 = styled.div`
  ${Body2};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;

const StDiv10 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const StDiv11 = styled.div`
  display: flex;
  width: 328px;
  justify-content: space-between;
`;

const StDiv12 = styled.div`
  display: flex;
  width: 100px;
  padding: ${spacing[16].value} ${spacing[12].value};
  flex-direction: column;
  align-items: center;
  gap: ${spacing[4].value};
  flex-shrink: 0;
  border-radius: ${borderRadius[5].value};
  background: ${contents.secondary.value};
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.09);
`;

const StDiv13 = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StDiv14 = styled.div`
  ${Header3};
  color: ${colors.gray[500].value};
  text-align: center;
  width: fit-content;
`;

const StDiv15 = styled.div`
  ${Header3};
  width: 12px;
  color: ${foundation.normal.primary.value};
  text-align: center;
`;

const StDiv16 = styled.div`
  ${Body1};
  width: 58px;
  color: ${contents.accent.value};
  text-align: center;
`;

const StDiv17 = styled.div`
  display: flex;
  width: 100px;
  padding: ${spacing[16].value} ${spacing[12].value};
  flex-direction: column;
  align-items: center;
  gap: ${spacing[4].value};
  flex-shrink: 0;
  border-radius: ${borderRadius[5].value};
  background: ${contents.secondary.value};
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.09);
`;

const StDiv18 = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StDiv19 = styled.div`
  ${Header3};
  width: 35px;
  color: ${colors.gray[500].value};
  text-align: center;
`;

const StDiv20 = styled.div`
  ${Header3};
  width: 12px;
  color: ${foundation.normal.primary.value};
  text-align: center;
`;

const StDiv21 = styled.div`
  ${Body1};
  width: 44px;
  color: ${contents.accent.value};
  text-align: center;
`;

const StDiv22 = styled.div`
  display: flex;
  width: 100px;
  padding: ${spacing[16].value} ${spacing[12].value};
  flex-direction: column;
  align-items: center;
  gap: ${spacing[4].value};
  flex-shrink: 0;
  border-radius: ${borderRadius[5].value};
  background: ${colors.gray["000"].value};
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.09);
`;

const StDiv23 = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StDiv24 = styled.div`
  ${Header3};
  width: 35px;
  color: ${colors.gray[500].value};
  text-align: center;
`;

const StDiv25 = styled.div`
  ${Header3};
  width: 12px;
  color: ${foundation.normal.primary.value};
  text-align: center;
`;

const StDiv26 = styled.div`
  ${Body1};
  width: 62px;
  color: ${contents.accent.value};
  text-align: center;
`;

const StDiv27 = styled.div`
  ${Body3};
  color: ${contents.accent.value};
  text-align: center;
  width: fit-content;
`;
