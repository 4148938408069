export const Kakao = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#F7E317" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9 14C17.88 14 13 17.92 13 22.76C13 25.9 15.07 28.66 18.17 30.21C17.94 31.07 17.35 33.34 17.22 33.83C17.07 34.43 17.44 34.42 17.68 34.26C17.87 34.13 20.67 32.2 21.87 31.36C22.54 31.46 23.22 31.51 23.89 31.51C29.91 31.51 34.79 27.59 34.79 22.76C34.79 17.93 29.92 14 23.9 14Z"
        fill="#392020"
      />
      <path
        d="M17.5921 21.4028H16.4821C16.3421 21.4028 16.2021 21.3728 16.0921 21.2928C15.9821 21.2228 15.9121 21.1128 15.8921 20.9928C15.8821 20.9628 15.8821 20.9228 15.8721 20.8828C15.8721 20.7328 15.9321 20.5928 16.0521 20.5028C16.1721 20.4028 16.3321 20.3628 16.4921 20.3628H19.8821C20.0221 20.3628 20.1521 20.3928 20.2721 20.4628C20.3821 20.5328 20.4521 20.6328 20.4821 20.7628C20.4821 20.8028 20.4921 20.8428 20.4921 20.8728C20.5021 21.0228 20.4321 21.1628 20.3221 21.2528C20.1921 21.3528 20.0421 21.4028 19.8821 21.3928H18.7721V25.2128C18.7821 25.3728 18.7221 25.5328 18.6121 25.6528C18.5021 25.7728 18.3421 25.8328 18.1821 25.8228C18.0521 25.8228 17.9121 25.7828 17.8021 25.7028C17.7021 25.6228 17.6221 25.5028 17.6021 25.3728C17.6021 25.3228 17.5921 25.2728 17.6021 25.2128L17.5921 21.4028Z"
        fill="#F7E317"
      />
      <path
        d="M20.7721 20.6429C20.8121 20.5229 20.8821 20.4229 20.9821 20.3529C21.0921 20.2929 21.2121 20.2629 21.3421 20.2729H21.6221C21.7521 20.2729 21.8921 20.3029 22.0021 20.3629C22.1321 20.4529 22.2121 20.5729 22.2521 20.7229L23.6721 24.8329C23.7121 24.9429 23.7421 25.0529 23.7721 25.1629C23.7721 25.2029 23.7821 25.2529 23.7921 25.3029C23.7921 25.4429 23.7321 25.5829 23.6221 25.6829C23.5221 25.7829 23.3821 25.8429 23.2321 25.8429C23.0021 25.8629 22.7821 25.7229 22.7021 25.5029L22.4021 24.6029H20.5121L20.2021 25.5029C20.1321 25.7229 19.9121 25.8629 19.6821 25.8329C19.5621 25.8329 19.4321 25.8029 19.3321 25.7229C19.2421 25.6529 19.1721 25.5429 19.1521 25.4229C19.1521 25.3829 19.1421 25.3429 19.1421 25.3029C19.1421 25.2329 19.1521 25.1529 19.1721 25.0829C19.1921 25.0029 19.2221 24.9129 19.2521 24.8329L20.7721 20.6429ZM21.4821 21.6029H21.4621L20.7721 23.7529H22.1521L21.4821 21.6029Z"
        fill="#F7E317"
      />
      <path
        d="M23.972 20.8828C23.962 20.7228 24.022 20.5628 24.132 20.4428C24.242 20.3228 24.402 20.2628 24.562 20.2728C24.702 20.2728 24.832 20.3128 24.942 20.3928C25.042 20.4728 25.112 20.5928 25.142 20.7228C25.152 20.7728 25.152 20.8228 25.152 20.8728V24.7028H27.112C27.252 24.6928 27.392 24.7328 27.512 24.8028C27.622 24.8728 27.692 24.9828 27.712 25.1028C27.722 25.1428 27.722 25.1728 27.732 25.2128C27.742 25.3628 27.672 25.5028 27.562 25.5928C27.432 25.6828 27.282 25.7328 27.122 25.7228H24.662C24.502 25.7428 24.352 25.7028 24.222 25.6228C24.102 25.5428 24.022 25.4028 24.002 25.2628C23.982 25.1828 23.972 25.1028 23.972 25.0228V20.8828Z"
        fill="#F7E317"
      />
      <path
        d="M27.832 20.8829C27.822 20.7229 27.882 20.5629 27.992 20.4429C28.102 20.3329 28.262 20.2729 28.422 20.2729C28.562 20.2729 28.692 20.3129 28.802 20.3929C28.912 20.4729 28.982 20.5929 29.002 20.7229C29.012 20.7729 29.022 20.8229 29.012 20.8729V22.6529L30.852 20.5329C30.922 20.4529 30.992 20.3929 31.072 20.3329C31.152 20.2929 31.232 20.2729 31.322 20.2729C31.452 20.2729 31.572 20.3129 31.672 20.3829C31.772 20.4429 31.852 20.5529 31.872 20.6729C31.872 20.6729 31.872 20.6929 31.882 20.7129C31.882 20.7329 31.882 20.7429 31.882 20.7629C31.882 20.8429 31.862 20.9129 31.832 20.9829C31.792 21.0529 31.742 21.1229 31.692 21.1829L30.482 22.5029L31.912 24.7629L31.952 24.8329C32.022 24.9329 32.082 25.0529 32.122 25.1729C32.122 25.1829 32.122 25.1929 32.132 25.2129C32.132 25.2229 32.132 25.2429 32.132 25.2529C32.152 25.4229 32.082 25.5929 31.952 25.7029C31.832 25.7929 31.682 25.8429 31.522 25.8429C31.422 25.8429 31.322 25.8229 31.232 25.7829C31.142 25.7129 31.062 25.6329 31.002 25.5229L29.652 23.3429L29.012 24.0129L29.022 25.2329C29.032 25.3929 28.972 25.5529 28.862 25.6729C28.752 25.7829 28.592 25.8529 28.432 25.8429C28.292 25.8429 28.162 25.8029 28.052 25.7229C27.952 25.6429 27.872 25.5229 27.852 25.3929C27.842 25.3429 27.842 25.2929 27.842 25.2329L27.832 20.8829Z"
        fill="#F7E317"
      />
    </svg>
  );
};
