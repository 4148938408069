export const Facebook = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#4267B2" />
      <path
        d="M29.0047 25.2482L29.5648 21.6317H26.0907V19.2776C26.0052 18.281 26.7361 17.4077 27.7328 17.3128C27.8657 17.3033 27.9986 17.3033 28.1315 17.3223H29.7072V14.2468C28.7864 14.0949 27.8467 14.0095 26.907 14C24.0498 14 22.1799 15.7371 22.1799 18.8695V21.6222H19V25.2387H22.1799V33.9905H26.0907V25.2387H29.0047V25.2482Z"
        fill="white"
      />
    </svg>
  );
};
